<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular py-3">
        NID OCR Billing Receipts
      </v-toolbar-title>
      <v-spacer />

      <v-btn outlined @click="() => this.fetchBillingList()">
        <v-icon class="mr-1">mdi-reload</v-icon> Reload
      </v-btn>
    </v-toolbar>

    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="tableHeaders"
          :items="receipts"
          item-key="id"
          class="elevation-2"
          style="cursor: pointer"
          hide-default-footer
          disable-pagination
          @click:row="openBillingDetails"
        >
          <template v-slot:[`item.month`]="{ item }">
            {{ getMonthName(item) }}
          </template>
          <template v-slot:[`item.charge`]="{ item }">
            {{ formatCurrency(item.charge) }}
          </template>
          <template v-slot:[`item.recurringCharge`]="{ item }">
            {{ formatCurrency(item.recurringCharge) }}
          </template>
          <template v-slot:[`item.totalCharge`]="{ item }">
            {{ formatCurrency(item.totalCharge) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip small>{{ item.status }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as api from "@/constants/api";
import moment from "moment";

export default {
  metaInfo: { title: "NID OCR Billing" },
  data() {
    return {
      loading: false,
      receipts: [],
    };
  },
  computed: {
    userId() {
      return Number(this.$route.query.userId) || this.$auth.user.id;
    },
    tableHeaders: () => [
      { text: "Year", value: "year" },
      { text: "Month", value: "month" },
      { text: "Count", value: "total" },
      { text: "Free", value: "free", sortable: false },
      { text: "Chargeable", value: "chargeable", sortable: false },
      { text: "Charge", value: "charge" },
      { text: "Recurring Cost", value: "recurringCharge", sortable: false },
      { text: "Total Charge", value: "totalCharge" },
      { text: "Payment", value: "status", sortable: false },
    ],
  },
  mounted() {
    this.fetchBillingList();
  },
  methods: {
    async fetchBillingList() {
      try {
        this.loading = true;
        const resp = await this.$axios.get(api.NID_OCR_BILLING_LIST, {
          params: { userId: this.userId },
        });
        this.receipts = resp.data.data.map((item) => {
          item.id = Math.random();
          return item;
        });
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    getMonthName({ year, month }) {
      return moment(new Date(year, month - 1)).format("MMMM");
    },
    formatCurrency(number) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "BDT" }).format(number);
    },
    openBillingDetails({ year, month }) {
      if (!this.verifyPrivilege(api.NID_OCR_BILLING_GET)) {
        return;
      }
      this.$router.push({
        name: "billing.view",
        query: { userId: this.userId, year, month },
      });
    },
  },
};
</script>
