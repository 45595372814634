var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"elevation":"1"}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-regular py-3"},[_vm._v(" NID OCR Billing Receipts ")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function () { return this$1.fetchBillingList(); }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-reload")]),_vm._v(" Reload ")],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.tableHeaders,"items":_vm.receipts,"item-key":"id","hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.openBillingDetails},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMonthName(item))+" ")]}},{key:"item.charge",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.charge))+" ")]}},{key:"item.recurringCharge",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.recurringCharge))+" ")]}},{key:"item.totalCharge",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.totalCharge))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.status))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }